<h3 class="panel-title">
  <span>Files</span>
</h3>

<div class="chipster-highlight">
  <!-- workflow and files tabs -->

  <ch-search-box
    placeholder="Find file"
    focusHotkey="f"
    focusHotkeyDescription="Find file"
    (valueChange)="search($event)"
    (enterKey)="searchEnter()"></ch-search-box>

  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <!-- Workflow tab-->
    <ng-container ngbNavItem>
      <a ngbNavLink>Workflow</a>
      <ng-template ngbNavContent>
        <div id="workflow-graph-panel">
          <div class="mt-3 mb-1 ms-1 me-1">
            <ch-add-dataset-modal [sessionId]="sessionDataService.getSessionId()"></ch-add-dataset-modal>

            <!-- context dropdown -->
            <div ngbDropdown class="d-inline-block float-end">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary tool-bar-button no-caret-dropdown"
                id="viewDropdownMenuButton"
                ngbDropdownToggle
                aria-haspopup="true"
                aria-expanded="false"
                title="Actions">
                <!-- <i class="fa-solid fa-bars" aria-hidden="true"></i> -->
                <i class="fas fa-lg fa-ellipsis-h" aria-hidden="true"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="viewDropdownMenuButton">
                <button ngbDropdownItem (click)="selectAll()">Select all files</button>
                <button ngbDropdownItem (click)="selectChildren()" [class.disabled]="!isDatasetsSelected()">
                  Select Descendants of Selected Files
                </button>
                <div class="dropdown-divider"></div>

                <button ngbDropdownItem (click)="autoLayoutAll()">Reset all positions</button>
                <button ngbDropdownItem (click)="autoLayoutSelected()" [class.disabled]="!isDatasetsSelected()">
                  Reset positions of selected files
                </button>
                <button ngbDropdownItem (click)="workflowGraph.resetZoomAndScroll()">Reset view</button>
              </div>
            </div>

            <!-- zoom buttons-->
            <button
              [disabled]="sessionData.datasetsMap.size === 0"
              class="btn btn-sm btn-outline-secondary me-2 float-end tool-bar-button"
              title="Zoom in"
              (click)="workflowGraph.zoomIn()">
              <span class="fa fa-lg fa-search-plus"></span>
            </button>
            <button
              [disabled]="sessionData.datasetsMap.size === 0"
              class="btn btn-sm btn-outline-secondary me-1 float-end tool-bar-button"
              title="Zoom out"
              (click)="workflowGraph.zoomOut()">
              <span class="fas fa-lg fa-search-minus"></span>
            </button>
          </div>

          <div class="ms-1">
            <ch-workflow-graph
              id="workflow-graph"
              #workflowGraph
              [datasetsMap]="sessionData.datasetsMap"
              [jobsMap]="sessionData.jobsMap"
              [modulesMap]="modulesMap"
              [datasetSearch]="datasetSearch"
              [defaultScale]="1"
              [enabled]="true"
              [sessionData]="sessionData"
              [tools]="tools"></ch-workflow-graph>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <!-- List tab-->
    <ng-container ngbNavItem>
      <a ngbNavLink>List</a>

      <ng-template ngbNavContent>
        <div class="mt-3 ms-1 mb-2">
          <ch-add-dataset-modal [sessionId]="sessionDataService.getSessionId()"></ch-add-dataset-modal>
        </div>

        <div id="file-list" class="p-0">
          <div class="list-group dataset-list list-group-sm pt-0">
            <button
              type="button "
              class="dataset-list-button list-group-item list-group-item-action"
              [ngClass]="{
                active: selectionService.isSelectedDatasetById(dataset.datasetId)
              }"
              (click)="toggleDatasetSelection($event, dataset)"
              *ngFor="let dataset of getDatasetListSorted() | datasetsearch: datasetSearch">
              {{ dataset.name }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
