<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button [disabled]="!closeEnabled" type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>

<div class="modal-body">
  <h3 class="h3-xs">
    {{ message }}
  </h3>

  <ag-grid-angular
    style="width: 100%; height: 400px"
    class="ag-theme-alpine"
    [gridOptions]="gridOptions"></ag-grid-angular>

  <button
    *ngIf="deleteVisible"
    [disabled]="!deleteEnabled"
    type="button"
    class="btn btn-sm btn-danger mt-3 mb-3 w-100"
    (click)="onDelete()">
    Delete
  </button>
  <button
    *ngIf="!deleteVisible"
    type="button"
    class="btn btn-sm btn-secondary mt-3 mb-3 w-100"
    (click)="onCancelDelete()">
    Cancel
  </button>
</div>

<div class="modal-footer">
  <button [disabled]="!closeEnabled" type="button" class="btn btn-sm btn-secondary" (click)="onClose()">Close</button>
</div>
